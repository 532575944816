"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var cookie_options_provider_1 = require("./src/cookie-options-provider");
var cookie_service_1 = require("./src/cookie.service");
var cookie_factory_1 = require("./src/cookie.factory");
__export(require("./src/cookie.service"));
__export(require("./src/cookie-backend.service"));
__export(require("./src/cookie-options-provider"));
__export(require("./src/cookie.factory"));
__export(require("./src/utils"));
var CookieModule = /** @class */ (function () {
    function CookieModule() {
    }
    CookieModule_1 = CookieModule;
    /**
     * Use this method in your root module to provide the CookieService
     * @param {CookieOptions} options
     * @returns {ModuleWithProviders}
     */
    CookieModule.forRoot = function (options) {
        if (options === void 0) { options = {}; }
        return {
            ngModule: CookieModule_1,
            providers: [
                { provide: cookie_options_provider_1.COOKIE_OPTIONS, useValue: options },
                { provide: cookie_service_1.CookieService, useFactory: cookie_factory_1.cookieServiceFactory, deps: [cookie_options_provider_1.CookieOptionsProvider] }
            ]
        };
    };
    /**
     * Use this method in your other (non root) modules to import the directive/pipe
     * @param {CookieOptions} options
     * @returns {ModuleWithProviders}
     */
    CookieModule.forChild = function (options) {
        if (options === void 0) { options = {}; }
        return {
            ngModule: CookieModule_1,
            providers: [
                { provide: cookie_options_provider_1.COOKIE_OPTIONS, useValue: options },
                { provide: cookie_service_1.CookieService, useFactory: cookie_factory_1.cookieServiceFactory, deps: [cookie_options_provider_1.CookieOptionsProvider] }
            ]
        };
    };
    CookieModule = CookieModule_1 = __decorate([
        core_1.NgModule({
            providers: [cookie_options_provider_1.CookieOptionsProvider]
        })
    ], CookieModule);
    return CookieModule;
    var CookieModule_1;
}());
exports.CookieModule = CookieModule;
